import { Injectable } from '@angular/core';
import { cad } from '@comm-apps/common';
import Settings = cad.Settings;

@Injectable()
export class AppSettings {
  public settings = new Settings(
    'Enable Bid Up', //name
    'EBU', //short Name
    true, //loadingSpinner
    true, //fixedHeader
    true, //sidenavIsOpened
    false, //sidenavUserBlock
    'vertical', //none , vertical
    'default', //default, compact, mini
    ['blue-dark', 'ag-theme-alpine-dark'],
    'ag-theme-alpine-dark',
    '../assets/img/app/white-logo.svg'
  );

}
