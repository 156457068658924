<div>
  <h1>You are not authorized to use this application.</h1>
</div>
<br/>
<mat-divider></mat-divider>
<br/>
<div>
  <!-- <h1>Access to this application is granted throught <a href="https://quorumcommercial.energytransfer.com/">Quorum</a>.  If you need access to quorum, please
    contact through your normal channels.
    <a href="mailto:quorumsupport@energytransfer.com?subject=Quorum Access&body=I would like to request the following access:%0D%0A%0D%0A(Please let us know what access you need.)">
      Quorum Support</a>
  </h1> -->
  <h2 *ngIf="!user || !user.userName">
    If you feel you have received this in error, please try to login again from the User Menu in the upper right.
  </h2>
  <div *ngIf="user && user.userName">
    <h2 *ngIf="!hasRoles">{{user.userName}} - you have successfully logged in using your Quorum Id, but you lack the priveleges to access this site.</h2>
    <h2 *ngIf="hasRoles">Redirecting you shortly...</h2>    
  </div>
</div>
