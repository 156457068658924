import { Component, OnInit } from '@angular/core';
import {AutoUnsubscribables, AutoUnsubscriber, Theme, ThemeService} from '@comm-apps/common';
import { AppSettings } from '../../app.settings';
import {BUUser, UserPrefs} from '../../core/classes/data-types';
import {BUUserService} from '../../services/user.service';
import { UserPrefsService } from '../../services/user-prefs.service';

@Component({
  selector: 'ldms-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  public user: BUUser;
  public themeList: Theme[];
  public currentTheme: Theme;
  private userPrefs: UserPrefs;
  public showTheme: boolean = false;

  constructor(
    private userService: BUUserService, 
    private userPrefService: UserPrefsService, 
    private themeService: ThemeService,
    private appSettings: AppSettings,
  ) { }

  ngOnInit() {
    this.user = this.userService.currentUser$.getValue();
    this.subs.newSub = this.userService.currentUser$.subscribe((user: BUUser) => {
      this.user = user;
    });

    this.currentTheme = this.getThemeFromName(this.appSettings.settings.themeList[0]);
    this.themeList = Array.from(ThemeService.themes.values());

    this.subs.newSub = this.userPrefService.userPrefs$.subscribe((prefs: UserPrefs) => {
      this.userPrefs = prefs;
      if (prefs.theme) {
        this.currentTheme = ThemeService.themes.get(prefs.theme);
      }
    });
  }

  private getThemeFromName(themeName: string): Theme {
    let rv: Theme = null;
    for (var theme of ThemeService.themes.values()) {
      if (theme.themeName === themeName) {
        rv = theme;
      }
    }
    return rv;
  }

  public setCurrentThemeVal(val) {
    if (this.currentTheme) {
      // this.themeService.updateTheme(this.currentTheme.description, this.appSettings.settings);
      this.userPrefs.theme = this.currentTheme.description;
      this.userPrefService.saveUserPrefs(this.userPrefs);
    }
  }

  public resetPrefs(): void {
    this.userPrefService.clearUserPrefs();
  }

  public toggleTheme(): void {
    this.showTheme = !this.showTheme;
  }
}
