import { Component, OnInit } from '@angular/core';

import { ColDef, GridOptions } from 'ag-grid-community';
import { AggridService, AutoUnsubscribables, AutoUnsubscriber } from '@comm-apps/common';
import { Shipper } from '../../models/Shipper';
import { BidDetail } from '../../models/BidDetail';
import { ShipperService } from '../../services/shipper-service';
import { BidDetailService } from '../../services/bid-detail-service';
import { BUUserService } from '../../services/user.service';
import { AlertService } from '@comm-apps/alert';
import { DoubleEditorComponent } from 'libs/shared-ui/src/lib/table/double-editor/double-editor.component';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  private gridApi

  public shipperList: Shipper[];

  public currentShipper: Shipper = {} as Shipper;
  public legalNotice: string;

  public detailData: BidDetail[];


  columnDefs: ColDef[] = [
    // { headerName: 'Accept Bid?', field: 'acceptBidInd', type: ['editable'], width: 120},
    { headerName: 'Sr Ctr No', field: 'srCtrNo' , width: 120},
    { headerName: 'Rec Loc Id', field: 'recLocId' , width: 100},
    { headerName: 'Rec Loc Nm', field: 'recLocNm' , width: 300},
    { headerName: 'Del Loc Id', field: 'delLocId' , width: 100},
    { headerName: 'Del Loc Nm', field: 'delLocNm' , width: 300},
    { headerName: 'Bid Qty', field: 'bidQty' , width: 100},
    { headerName: 'Current Rate', field: 'currentRate' , width: 120},
    { headerName: 'Requested Rate', field: 'finalRate', cellEditor: 'doubleEditor', cellEditorParams: { maxDecimals: 6 },  type: ['editable'] , width: 150, cellClass: 'editable-cell'},
    { headerName: 'Bid Up Status', field: 'bidupStatus' , width: 120},
    { headerName: 'Awarded Qty', field: 'awardedQty', width: 120 },
  ]

  public defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true
  }

  public gridOptions: GridOptions = {
    singleClickEdit: true,
  }

  public columnTypes = this.gridService.columnTypes;

  public frameworkComponents = this.gridService.frameworkComponents;

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  constructor(
    private gridService: AggridService,
    private shipperService: ShipperService,
    private bidDetailService: BidDetailService,
    private userService: BUUserService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit(): void {
    this.currentShipper = {} as Shipper;
    this.detailData = [];
    this.shipperList = [];

    this.userService.currentUser$.subscribe(user => {
      if (user && user.userName) {
        this.festchShipperList();
      }
    });
  }

  fetchPageInfo(): void {

  }

  fetchBids(shipper: Shipper): void {
    this.detailData = [];
    this.bidDetailService.findAll(shipper).subscribe(bids => {
      this.detailData = bids;
    });
  }

  festchShipperList(): void {
    this.currentShipper = {} as Shipper;
    this.detailData = [];
    this.shipperService.findAll().subscribe(shippers => {
      this.shipperList  = shippers;
      if (shippers && shippers[0]) {
        this.currentShipper = shippers[0];
        this.shipperSelected(this.currentShipper);
      }
    });
  }

  public shipperSelected(event: any) {
    this.fetchBids(event as Shipper);
    this.legalNotice = event.legalDoc1 + event.legalDoc2 + event.legalDoc3;
  }

  public submit():void {
    this.gridApi.stopEditing();
    this.bidDetailService.saveAll(this.detailData).subscribe((bids) => {
      this.detailData = bids;
      this.alertService.success('Successfully submitted bids for this shipper.');
    });
  }


  onGridReady(params): void {
    this.gridApi = params.api;
  }


}
