import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {RouteHelperService} from '@comm-apps/common';
import { BUUser } from '../../core/classes/data-types';
import { Roles } from '../../models/roles.enum';
import {BUUserService} from '../../services/user.service';

@Component({
  selector: 'ldms-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

  public user: BUUser;

  public hasRoles: boolean = false;

  constructor(private userSvc: BUUserService, private router: Router, private routeHelper: RouteHelperService) {}

  ngOnInit() {
    let reRouted: boolean = false;
    this.userSvc.clearUser();
    const sub = this.userSvc.currentUser$.subscribe(user => {
      this.user = user;
      this.hasRoles = false;
      if (user && user.userName && !reRouted) {
        this.hasRoles = (this.user.roles.indexOf(Roles.INTERNAL) >= 0) || (this.user.roles.indexOf(Roles.EXTERNAL) >= 0);
        //Make sure we are flagging this so it does not happen again.  Sometimes this
        // will call over and over (since we are in a subject) before this component
        // is destroyed and we can get in an infinite loop
        // reRouted = true;
        setTimeout(() => {
          //this.routeHelper.gotoPrevious();
          //we have one page - so just go there.
          this.router.navigate(['']);
        }, 500);
      }
    }, () =>{}, () => sub.unsubscribe());
  }
}
