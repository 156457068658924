<mat-card appearance="outlined" (keydown.control.shift.z)="toggleTheme()"> 
  <mat-card-header><h2>{{user.lastName}}'s Profile</h2></mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutAlign="center start">
    <div fxFlex>UserName: {{user.userName}}</div>
    <div fxFlex>Name: {{user.lastName}}</div>
    <div fxFlex>Email: {{user.email}}</div>
    <div fxFlex>User Roles: {{user.roles}}</div>
    <!-- <button fxFlex  mat-button color="primary" (click)="resetPrefs()">Reset User Prefs</button> -->
    <div fxFlex *ngIf="showTheme">
      <cad-lib-auto-complete
                      fxFlex
                      matTooltip="Select your Theme"
                      placeholder="User Theme"
                      fxFlex
                      [(selectedData)]="currentTheme"
                      [data]="themeList"
                      displayProperty="description"
                      (valueChanged)="setCurrentThemeVal($event)"
                      >
      </cad-lib-auto-complete>
      <!-- <button fxFlex  mat-button color="primary" (click)="setCurrentThemeVal()">Apply Theme</button> -->
    </div>
  </mat-card-content>
</mat-card>
