import {Injectable} from "@angular/core";
import {AuthGroup} from "../core/classes/data-types";
import {BUUserService} from "./user.service";
import {Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {

  private subs: Subscription = new Subscription();
  private userRoles: string[] = [];

  constructor(private userService: BUUserService,) {
    this.subs.add(this.userService.currentUser$.subscribe((user) => {
      this.userRoles = user.roles;
    }));
  }

  hasPermission(authGroup: AuthGroup[]) {
    if (this.userRoles) {
      return this.userRoles.find((role) => {
        return authGroup.find((auth) => {
          return role === auth;
        });
      });

    }
    return false;
  }
}
