import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutoUnsubscriber, AutoUnsubscribables, AggridService } from '@comm-apps/common';
import { RoleService } from '../../../services/role.service';
import { Role } from '../../../core/classes/data-types';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'lp-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit, OnDestroy {

  public rowData: Role[];
  public selectedRow: Role;
  public filter: string = '';
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  public isAppAdmin: boolean = false;

  private gridApi;
  private gridColumnApi;
  private windowEventFunction;
  public gridOptions: GridOptions;
  public columnDefs = [
    {headerName: 'Role', field: 'roleName'},
    {headerName: 'Role Desc', field: 'roleDescription'},
    {headerName: 'User Id', field: 'userId'},
    {headerName: 'User Name', field: 'userFullName'},
    {headerName: 'Email', field: 'email'},
  ];

  public defaultColDef = {
    flex: 1,
    filter: true,
    sortable: true,
    resizable: true,
  };

  public columnTypes = this.gridService.columnTypes;
  

  constructor(
    private roleService: RoleService,
    public dialog: MatDialog,
    public gridService: AggridService
  ) {
    this.gridOptions = <GridOptions>{
      getRowId: (data) => `${data.data.userId}:${data.data.roleName}`
  };
  }

  ngOnInit() {
    this.subs.newSub = this.roleService.filter().subscribe(tariffs => {
      this.rowData = tariffs;
    });
    // this.subs.newSub = this.userService.currentUserPermissions$.subscribe(userPermissions => {
    //   this.isAppAdmin = userPermissions.appPermissions.isAppAdmin;
    // });
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.windowEventFunction);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let service: AggridService = this.gridService;

    this.windowEventFunction = () => {
      Promise.resolve().then(() => {
        service.bestFit(params.columnApi, params.api);
      });
    }
    window.addEventListener('resize', this.windowEventFunction);

    this.gridService.bestFit(this.gridColumnApi, this.gridApi);
  }

  public cellValueChanged(event): void {
    // this.dirtyRowSet.add(event.node.data);
  }

  public onRowSelected(event) {
    if (!event.node.selected) return;  //Get the unslected even here too - ignore that
    this.selectedRow = event.data;
    }

  // public newRole() {
  //   const dialogRef = this.dialog.open(EditRoleDialogComponent, {
  //     height: '300px',
  //     width: '400px',
  //     data: { role: new Role() }
  //   });
  //   let sub: Subscription;
  //   sub = dialogRef.afterClosed().subscribe((result : Role) => {
  //     if(result) {
  //       const updatedList = (JSON.parse(JSON.stringify(this.roleDataSource.data)) as Role[]);
  //       updatedList.push(result);
  //       this.roleDataSource = new MatTableDataSource(updatedList);
  //       this.roleDataSource.filterPredicate = this.filterPredicate;
  //       this.roleDataSource.sort = this.sort;
  //       this.applyFilter(this.filter);
  //     }
  //   }, null, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
  // }

  // public editRole(role: Role) {
  //   if (!this.isAppAdmin) return;

  //   if (role.id) {
  //     const dialogRef = this.dialog.open(EditRoleDialogComponent, {
  //       height: '300px',
  //       width: '400px',
  //       data: {role: role}
  //     });
  //     let sub: Subscription;
  //     sub = dialogRef.afterClosed().subscribe((result: Role) => {
  //       if (result) {
  //         const updatedList = (JSON.parse(JSON.stringify(this.roleDataSource.data)) as Role[]);
  //         const index = updatedList.findIndex(x => x.id === result.id);
  //         updatedList.splice(index, 1, result);
  //         this.roleDataSource = new MatTableDataSource(updatedList);
  //         this.roleDataSource.filterPredicate = this.filterPredicate;
  //         this.roleDataSource.sort = this.sort;
  //         this.applyFilter(this.filter);
  //       }
  //     }, null, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
  //   } else {
  //     //todo - you cannot edit this item..  or some other way.
  //   }
  // }

  // public delete( item: Role, event: MouseEvent ) {
  //   event.stopPropagation();
  //   const dialogRef = this.dialog.open(ConfirmationComponent, {
  //     disableClose: false
  //   });
  //   dialogRef.componentInstance.message = 'Are you sure you want to delete?';
  //   dialogRef.componentInstance.title = 'Delete?';
  //   let sub: Subscription;
  //   sub = dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       let aSub: Subscription;
  //       aSub = this.roleService.delete(item)
  //       .subscribe(codeValue => {
  //         let bSub: Subscription;
  //         bSub = this.roleService.filter()
  //       .subscribe(codeValues => {
  //          this.roleDataSource = new MatTableDataSource(codeValues);
  //          this.roleDataSource.filterPredicate = this.filterPredicate;
  //          this.roleDataSource.sort = this.sort;
  //          this.applyFilter(this.filter);
  //       }, null, () => bSub && bSub.unsubscribe ? bSub.unsubscribe() : null
  //          );
  //       }, (error) => {
  //         this.alertService.danger('There was an error deleting the role', error);
  //       }, () => aSub && aSub.unsubscribe ? aSub.unsubscribe() : null);
  //     }
  //   }, null, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
  // }

}
