<div>
  <h1>Helpful hints on menu items and navigation</h1>
  <img src="../../../assets/img/Menus1.jpg"/><BR/><BR/><BR/><BR/><BR/>
  <h1>Alerts and User Context menu</h1>
  <img src="../../../assets/img/Menus2.jpg"/><BR/><BR/><BR/><BR/><BR/>
  <h1>User Security actions</h1>
  <img src="../../../assets/img/Page1.jpg"/><BR/><BR/><BR/><BR/><BR/>
  <h1>Updating a user</h1>
  <img src="../../../assets/img/EditUser1.jpg"/><BR/><BR/><BR/><BR/><BR/>
  <h1>Unlocking and Deleting a user</h1>
  <img src="../../../assets/img/EditUser2.jpg"/>
</div>
