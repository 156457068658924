import {Injectable} from '@angular/core';
import {HttpHelperService} from '@comm-apps/http';
import {Observable} from 'rxjs';
import { CodeMappingService } from './code-mapping.service';
import { Shipper } from '../models/Shipper';

@Injectable({
  providedIn: 'root'
})
export class ShipperService {

  private endpoint: string = 'api/shipper';

  constructor(private apiHelper: HttpHelperService, private codeMappingService: CodeMappingService) {
  }

  findAll(): Observable<Shipper[]> {
    return this.apiHelper.request<Shipper[]>(`${ this.endpoint }/all` );
  }

}
