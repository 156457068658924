import {Injectable} from '@angular/core';
import {CadLoginCallbackService} from '@comm-apps/shared-ui';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {AutoUnsubscribables, AutoUnsubscriber} from '@comm-apps/common';
import {HttpHelperService} from '@comm-apps/http';
import {LogService} from '@comm-apps/log';
import {share} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {BUUser} from '../core/classes/data-types';
import {UserPrefsService} from "./user-prefs.service";


export const blankUser = <BUUser> {
  userName: undefined,
  firstName: undefined,
  lastName: undefined,
  roles: [],
};

@Injectable({
  providedIn: 'root'
})
export class BUUserService implements CadLoginCallbackService {

  //Since this Service is Provided AND injected, there are two instances.  We only want one Subject.
  static currentUserHolder$: BehaviorSubject<BUUser> = new BehaviorSubject<BUUser>(
    blankUser
  );

  public currentUser$: BehaviorSubject<BUUser> = BUUserService.currentUserHolder$;

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;
  static user: BUUser = blankUser;

  constructor(private apiHelper: HttpHelperService, private log: LogService, private userPrefsService: UserPrefsService) {
  }

  public loginUser(): Observable<BUUser> {

    this.log.debug('Logging in the user');
    const subj: Subject<BUUser> = new Subject<BUUser>();

    this.apiHelper.request('api/auth/login').subscribe(rv => {
        if (rv && rv.userName) {
          // this.userPrefsService.loadUserPrefs();

          let hold: BUUser = _.clone(BUUserService.user);  //save the defaulted PMO Code if there was one.
          BUUserService.user = rv;
          // this.user.regionCd = hold.regionCd;
          // this.user.date = hold.date;

          BUUserService.user.success = true;
          BUUserService.user.userName = BUUserService.user.userName;
        } else {
          BUUserService.user = _.clone(blankUser);
        }
        this.currentUser$.next(BUUserService.user);
        subj.next(BUUserService.user);
      }, error => {
        BUUserService.user = _.clone(blankUser);  //don't want to set the error message on the blank user copy.
        BUUserService.user.errorMessage = 'Your ID and/or Password is invalid.';
        localStorage.removeItem('X-On-Behalf-Of');
        this.currentUser$.next(BUUserService.user);
        subj.next(BUUserService.user);
      }
    );
    return subj.asObservable();
  }

  public logoutUser(): Observable<any> {
    this.log.debug('Logging out the user');
    BUUserService.user = blankUser;
    this.currentUser$.next(BUUserService.user);
    localStorage.removeItem('X-On-Behalf-Of');
    return this.apiHelper.request('api/auth/logout').pipe(share());
  }

  public clearUser(): void {
    this.log.debug('Clearing user without Logout');

    BUUserService.user = blankUser;

    this.currentUser$.next(BUUserService.user);
  }

  public isLoggedIn(): boolean {
    return BUUserService.user.userName && true;
  }

}
