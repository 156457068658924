import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from '../../core/core.module';
import {FormsModule} from '@angular/forms';
import {SharedUiModule} from '@comm-apps/shared-ui';
import {HttpModule} from '@comm-apps/http';
import { MatButtonModule } from '@angular/material/button';
import {RouterModule, Routes} from '@angular/router';
import {ViewComponent} from '../../core/view/view.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {AdminGuard} from '../../guards/admin.guard';
import {AgGridModule} from 'ag-grid-angular';
import {CodeValueComponent} from './code-value/code-value.component';
import { ViewUserActivityComponent } from './view-user-activity/view-user-activity.component';
import { EditCodeValueDialogComponent } from './code-value/edit-code-value-dialog/edit-code-value-dialog.component';
import { RoleComponent } from './role/role.component';

const adminRoutes: Routes = [
  {
    path: 'admin',
    component: ViewComponent,
    canActivate: [AdminGuard],
    data: { breadcrumb: 'Admin' },
    children: [
      {
        path: '',
        redirectTo: '/admin/codes',
        pathMatch: 'full'
      },
      {
        path: 'view-user-activity',
        component: ViewUserActivityComponent,
        data: { breadcrumb: 'View User Activity', }
      },
      {
        path: 'role',
        component: RoleComponent,
        data: { breadcrumb: 'User Security Roles', }
      },
      {
        path: 'codes',
        data: { breadcrumb: 'Codes' },
        children: [
          {
            path: 'all',
            component: CodeValueComponent,
            data: { breadcrumb: 'All' }
          },
          {
            path: 'area',
            component: CodeValueComponent,
            data: { breadcrumb: 'Area Codes', codeType: 'AreaCode' },
          },
          {
            path: 'pipe',
            component: CodeValueComponent,
            data: { breadcrumb: 'Pipe Codes', codeType: 'PipeCode' },
          }
        ]
      },
    ]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(adminRoutes),
        CoreModule,
        FormsModule,
        CommonModule,
        SharedUiModule,
        HttpModule,
        MatButtonModule,
        MatCheckboxModule,
        DragDropModule,
        AgGridModule,
    ],
    declarations: [
        CodeValueComponent,
        EditCodeValueDialogComponent,
        ViewUserActivityComponent,
        RoleComponent,
    ]
})
export class AdminModule { }
