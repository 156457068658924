/* 'Barrel' of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {TimeoutInterceptor} from '@comm-apps/http';
import { AuthInterceptor } from 'libs/http/src/lib/http-interceptors/auth-interceptor';
import { LoggingInterceptor } from 'libs/http/src/lib/http-interceptors/logging-interceptor';
import { SpinnerInterceptor } from 'libs/http/src/lib/http-interceptors/spinner-interceptor';

/** Http interceptor providers in outside-in order */
export const localHttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }
];
