import {BidUpEnvironment} from "../app/core/classes/bu-environment";

export const environment: BidUpEnvironment = {
  production: true,
  localDevelopment: false,
  baseUrl: '',
  logLevel: 'INFO',
  name: 'Q3',
  runAs: true,
};
