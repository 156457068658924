import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { AppSettings } from '../../../app.settings';
import {AutoUnsubscribables, AutoUnsubscriber, cad} from '@comm-apps/common';
import Settings = cad.Settings;
import {LogService} from '@comm-apps/log';
import {BUUserService} from '../../../services/user.service';
import {BidUpEnvironment} from '../../classes/bu-environment';
import {BUUser} from '../../classes/data-types';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Roles } from '../../../models/roles.enum';

@Component({
  selector: 'cad-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Output()
  toggle = new EventEmitter();
  public toggleSearchBar = false;
  public userName: string;
  public searching: boolean = false;
  public canRunAs: boolean = false;

  public smallScreen: boolean = false;

  public settings: Settings;

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  constructor(
    public appSettings: AppSettings,
    private log: LogService,
    private userService: BUUserService,
    private breakpointObserver: BreakpointObserver,
    @Inject('environment') public env: BidUpEnvironment,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.subs.newSub = this.userService.currentUser$.subscribe((user: BUUser): void => {
      if (!this.userName) {
        this.setName(user);
        this.setCanRunAs(user);
      }
      this.setName(user);
    });

    this.subs.newSub = this.breakpointObserver.observe([
      Breakpoints.TabletLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.smallScreen = true;
      } else {
        this.smallScreen = false;
      }
    });  }

  toggleSidenav(): void {
    this.toggle.emit();
  }

  public loginComplete(user: string): void {
    //This is a good place to let your user service know, if you have one
    this.log.debug(`(Toolbar)User logged in - ${user} - we have stored their basic credentials in the http-helper service for external calls.`);
  }
  public logoutComplete(user: string): void {
    //This is a good place to let your user service know, if you have one
    this.log.debug(`(Toolbar)User logged out - ${user}`);
  }
  public actionPerformed(action: string) {
    this.log.debug(`An action was performed, you may want to do something - Action:${action}`);
  }

  public startSearch(): void {
    this.searching = true;
    setTimeout(() =>{this.searching = false;}, 2000);
  }

  private setName(user: BUUser): void {
    if (user.lastName) {
      this.userName = user.firstName + " " + user.lastName;
    } else {
      this.userName = user.userName
    }
  }

  private setCanRunAs(user:BUUser) {
    this.canRunAs = this.env.runAs && user.roles.indexOf(Roles.INTERNAL) >= 0
  }

  public runAsComplete(user: string) {
    this.log.info('Run as user ' + user);
    this.userService.loginUser();
  }

  public stopRunAsComplete(event: any) {
    this.log.info('Stopping run as');
    this.userService.logoutUser();
    this.userService.loginUser();
  }

  public currentUser(): string {
    return this.userService.currentUser$.getValue().userName;
  }

}
