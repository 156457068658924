import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deadline'
})
export class DeadlinePipe implements PipeTransform {

  transform(value: any, args?: any): string {
    let secondsRem = (Math.floor((value) % 60) >= 10) ? Math.floor((value) % 60) : "0" + Math.floor((value) % 60);
    let minutesRem = (Math.floor((value) / (60) % 60) >= 10) ? Math.floor((value) / (60) % 60) : "0" + Math.floor((value) / (60) % 60);
    let hoursRem = (Math.floor((value) / (60 * 60) % 24) >= 10) ? Math.floor((value) / (60 * 60) % 24) : "0" + Math.floor((value) / (60 * 60) % 24);
    return `${hoursRem}:${minutesRem}:${secondsRem}`;
  }

}
