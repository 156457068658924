import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {LicenseManager} from 'ag-grid-enterprise';


LicenseManager.setLicenseKey('CompanyName=SHI International Corp_on_behalf_of_Energy Transfer Partners, L.P.,LicensedGroup=ITCA,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-011166,ExpiryDate=15_October_2021_[v2]_MTYzNDI1MjQwMDAwMA==7fbb9a3c701eea39ea6a246c349b8558');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
