<div fxFlex fxLayout="column">
    <div class="card-container" fxFlex class="padded">
        <mat-card appearance="outlined" fxFlex fxLayout="column">
            <mat-card-header>
                <mat-card-title fxFlex>
                    Transport Rate Bid Form
                </mat-card-title>
            </mat-card-header>

            <div fxLayout="column">
                <div fxFlex fxLayoutGap="1em" fxLayout="column">
                    <cad-lib-auto-complete
                        fxFlex
                        matTooltip="Select a shipper"
                        placeholder="Shipper"
                        [data]="shipperList"
                        [(selectedData)]="currentShipper"
                        displayProperty="shipperBpNm"
                        (valueChanged)="shipperSelected($event)"
                        >
                    </cad-lib-auto-complete>
                    <mat-form-field fxFlex>
                        <mat-label>Legal Doc</mat-label>
                        <textarea matInput [(ngModel)]="legalNotice" name="legalDoc"
                            cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6"
                            disabled></textarea>
                    </mat-form-field>
                </div>
                <div fxFlex fxLayout="row" fxLayoutGap="1em">
                    <mat-form-field fxFlex>
                        <mat-label>Transporter</mat-label>
                        <input matInput [(ngModel)]="currentShipper.transporter"
                            name="transporter" disabled />
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>Shipper</mat-label>
                        <input matInput [(ngModel)]="currentShipper.shipperBpNm" name="shipperBpNm"
                            disabled />
                    </mat-form-field>
                </div>
                <div fxFlex fxLayout="row" fxLayoutGap="1em">
                    <mat-form-field fxFlex>
                        <mat-label>Bid Request Number</mat-label>
                        <input matInput [(ngModel)]="currentShipper.bidRequestNo"
                            name="bidRequestNo" disabled />
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>Enable Rep</mat-label>
                        <input matInput [(ngModel)]="currentShipper.enableRep" name="enableRep"
                            disabled />
                    </mat-form-field>
                </div>
                <div fxFlex fxLayout="row" fxLayoutGap="1em">
                    <mat-form-field fxFlex>
                        <mat-label>Trader</mat-label>
                        <input matInput [(ngModel)]="currentShipper.traderNm"
                            name="traderNm" disabled />
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>Bid Due By</mat-label>
                        <input matInput [(ngModel)]="currentShipper.bidDueDate" name="bidDueDate"
                            disabled />
                    </mat-form-field>
                </div>
                <div fxFlex fxLayout="row" fxLayoutGap="1em">
                    <mat-form-field fxFlex>
                        <mat-label>Bid Submitted By</mat-label>
                        <input matInput [(ngModel)]="currentShipper.bidSubmittedBy"
                            name="bidSubmittedBy" disabled />
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>Bid Complete Dt</mat-label>
                        <input matInput [(ngModel)]="currentShipper.bidCompleteDt" name="bidCompleteDt"
                            disabled />
                    </mat-form-field>
                </div>
            </div>

        </mat-card>
    </div>
    <div class="card-container" fxFlex="fill" class="padded">
        <mat-card appearance="outlined" fxFlex fxLayout="column">
            <mat-card-header>
                <mat-card-title fxFlex>
                    <div fxFlexLayout="row">
                        <div fxFlex>Bids</div>
                        <div class="menu-wrapper" fxFlex fxLayoutAlign="end center">
                            <button (click)="submit()" mat-mini-fab color="primary" matTooltip="Submit All">
                                <mat-icon>save</mat-icon>
                              </button>
                          </div>
                      </div>
                </mat-card-title>                
            </mat-card-header>
            <mat-card-content fxFlex fxLayout="column">
                <ag-grid-angular fxFlex 
                    style="flex-grow: 1" 
                    [columnDefs]="columnDefs" 
                    [columnTypes]="columnTypes"
                    [gridOptions]="gridOptions"
                    [defaultColDef]="defaultColDef" 
                    [components]="frameworkComponents"
                    [rowData]="detailData" 
                    (gridReady)="onGridReady($event)">
                </ag-grid-angular>
                * Shipper, by its acceptance, certifies to Transporter that Shipper will have good title to the gas delivered or caused to be delivered to Transporter, to the extent required by FERC, during the transportation of such gas on Transporter's system. If this Bid Form is incorrect, please call or IM your Transporter's Marketer to request a change.
            </mat-card-content>
        </mat-card>
    </div>
</div>