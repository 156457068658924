
import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from '@comm-apps/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '@comm-apps/alert';
import { Subscription } from 'rxjs';
import { CodeData } from '../../../../core/classes/data-types';
import { CodeDataService } from '../../../../services/code-data.service';
import { CodeType } from '../../../../core/enums/enums';

@Component({
  selector: 'ldms-edit-code-value-dialog',
  templateUrl: './edit-code-value-dialog.component.html',
  styleUrls: ['./edit-code-value-dialog.component.scss']
})
export class EditCodeValueDialogComponent implements OnInit {

  public codeValue: CodeData;
  public disableCodeType: boolean = false;
  public codeValueTypes: string[] = [];
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    private codeValueService: CodeDataService,
    public dialogRef: MatDialogRef<CodeData>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.codeValue = JSON.parse(JSON.stringify(data.codeValue));
    this.codeValueTypes = Object.keys(CodeType).map( x => CodeType[x]  ).sort(( a, b ) =>  this.compare(a, b, true));

    if (data.codeType) {
      this.codeValue.type = data.codeType;
      this.disableCodeType = true;
    }

  }

  ngOnInit() {
  }

  compare(a: number | string | Date , b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  save() {
    let sub: Subscription;
    sub = this.codeValueService.save(this.codeValue).subscribe(codeValue => {
      this.dialogRef.close(codeValue);
    },  (error) => {
      this.alertService.danger('There was an error saving the code value', error);
    }, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
