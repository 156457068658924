import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, } from 'rxjs';
import {AutoUnsubscribables, AutoUnsubscriber} from '@comm-apps/common';
import {HttpHelperService} from '@comm-apps/http';
import {LogService} from '@comm-apps/log';
import { UserPrefs} from '../core/classes/data-types';

export const blankPrefs = <UserPrefs> {};

@Injectable({
  providedIn: 'root'
})
export class UserPrefsService  {

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  static userPrefsHolder$: BehaviorSubject<UserPrefs> = new BehaviorSubject<UserPrefs>(
    blankPrefs
  );

  public userPrefs$: BehaviorSubject<UserPrefs> = UserPrefsService.userPrefsHolder$;

  constructor(private apiHelper: HttpHelperService, private log: LogService) {

  }

  public loadUserPrefs() {
    this.apiHelper.request('api/user-prefs/', { method: 'GET'}).subscribe((result: UserPrefs) => {
      if (result) {
        this.userPrefs$.next(result);
        this.log.debug("Pulled User preferences from server", result);
      }
    })
  }

  public saveUserPrefs(prefs: UserPrefs) {
    this.apiHelper.request('api/user-prefs/', {body: prefs, method: 'POST'}).subscribe(() => {
      this.log.debug("Save User Prefs Successfully");
      this.userPrefs$.next(prefs)
    })
  }
  
  public clearUserPrefs(): void {
    this.saveUserPrefs(<UserPrefs>{});
  }
}
