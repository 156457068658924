import {Injectable} from '@angular/core';
import {HttpHelperService} from '@comm-apps/http';
import {Observable} from 'rxjs';
import {CodeData} from '../core/classes/data-types';
import {map, share} from 'rxjs/operators';
import { CACHE_AGE_MINS, } from '../core/enums/enums'
import { CodeMappingService } from './code-mapping.service';
import { BidDetail } from '../models/BidDetail';
import { Shipper } from '../models/Shipper';

@Injectable({
  providedIn: 'root'
})
export class BidDetailService {

  private endpoint: string = 'api/bid-detail';

  constructor(private apiHelper: HttpHelperService, private codeMappingService: CodeMappingService) {
  }

  findAll(shipper: Shipper): Observable<BidDetail[]> {
    return this.apiHelper.request<BidDetail[]>(`${ this.endpoint }/${shipper.shipperBpNo}` );
  }

  saveAll(bids: BidDetail[]): Observable<BidDetail[]> {
    return this.apiHelper.request<BidDetail[]>(`${this.endpoint}/save-all`, {body: bids, method: 'POST'})
  }

}
