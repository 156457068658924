import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {ViewComponent} from './core/view/view.component';
import {NotAuthorizedComponent} from './pages/not-authorized/not-authorized.component';
import {AppGuard} from './guards/app/app.guard';
import {UserProfileComponent} from './pages/user-profile/user-profile.component';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HelpComponent } from './pages/help/help.component';

export const routes: Routes = [
  {
    path: '',
    component: ViewComponent,
    canActivate: [ ],
    children: [
      {
        path: '',
        // loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        component: DashboardComponent,
        data: { breadcrumb: 'Dashboard' },
        canActivate: [ AppGuard ],
      },
      {
        path: 'not-authorized',
        component: NotAuthorizedComponent,
        data: {breadcrumb: 'Not Authorized'},
      },
      {
        path: 'help',
        component: HelpComponent,
        data: {breadcrumb: 'Help'},
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        data: { breadcrumb: 'User Profile' },
        canActivate: [ AppGuard ],
      },
    ]
  }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
 // preloadingStrategy: PreloadAllModules // <- comment this line for activate lazy load
  // useHash: true
});
