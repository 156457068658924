<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>CODE VALUE</h1>
<form #form="ngForm" (ngSubmit)="save()">
    <div mat-dialog-content fxFlex fxLayout="column" >
      <mat-form-field>
        <mat-label>Code</mat-label>
        <input
          matInput
          required
          maxlength="25"
          name="code"
          [(ngModel)]="codeValue.code"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input
          matInput
          required
          maxlength="100"
          name="codeDescription"
          [(ngModel)]="codeValue.description"
        />
      </mat-form-field>

      <mat-checkbox matInput name="enabled" labelPosition="before" [(ngModel)]="codeValue.enabled">Enabled</mat-checkbox><br/>

      <cad-lib-auto-complete
        placeholder="Code Value Type"
        [required]="true"
        [disabled]="disableCodeType"
        [data]="codeValueTypes"
        [(selectedData)]="codeValue.type">
      </cad-lib-auto-complete>

    </div>
</form>

<div mat-dialog-actions>
  <button type="submit" mat-raised-button color="primary" (click)="save()" [disabled]="!form.form.valid" >SAVE</button>
  <button mat-raised-button (click)="cancel()">CANCEL</button>
</div>
