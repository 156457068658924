import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eds-app',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
