import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of, take} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {CadLoginDialogService} from '@comm-apps/shared-ui';
import {BUUserService} from '../../services/user.service';
import {LogService} from '@comm-apps/log';
import { Roles } from '../../models/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  constructor(private userService: BUUserService, private router: Router, private loginSvc: CadLoginDialogService, private log: LogService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    if (this.userService.isLoggedIn()) {
      const hasRoles = (this.userService.currentUser$.getValue().roles.indexOf(Roles.INTERNAL) >= 0) || 
        (this.userService.currentUser$.getValue().roles.indexOf(Roles.EXTERNAL) >= 0);
      return of(hasRoles);
    }

    // setTimeout(() => {
    this.router.navigate(['/not-authorized']);
    this.loginSvc.login();  
    // });

    return of(false);
  }
}
